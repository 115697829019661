import React, { useEffect, useState } from "react";
import styled from "styled-components";

import Footer from "../components/Footer";
import RecipeButtons from "../components/recipes/RecipeButtons";
import Nav from "../components/Nav";
import { useMediaQuery } from "@mui/material";
import ExploreRecipes from "../components/ExploreRecipes";
import { useLocation } from "react-router";
import { fetchSingleRecipeById } from "../utils/recipesHandler";
import BackdropWrapper from "../components/modals/BackdropWrapper";
import RecipeDetails from "../components/modals/RecipeDetails";
import { auth } from "../authentication/firebase";

let queryRecipeData = "";
function Explore() {
  const mediaQueryBtn = useMediaQuery("(min-width: 600px)");
  const location = useLocation();
  const [recipeDetailsModal, setRecipeDetailsModal] = useState(false);

  // Create a URLSearchParams object to work with the query string
  const queryParams = new URLSearchParams(location.search);

  // Get a specific query parameter by name
  const recipeId = queryParams.get("recipe-id");
  let recipeType = queryParams.get("recipe-type");
  if (recipeType) {
    recipeType = recipeType === "ai" ? "gpt" : recipeType;
  } else {
    recipeType = "gpt";
  }

  console.log("recipeId", recipeId);

  useEffect(() => {
    async function getRecipe() {
      queryRecipeData = await fetchSingleRecipeById(recipeId, recipeType);
      console.log("query recipe data", queryRecipeData);
      if (queryRecipeData) {
        if (
          recipeType === "converted" &&
          queryRecipeData?.uid !== auth.currentUser.uid
        ) {
          return;
        }
        setRecipeDetailsModal(true);
      }
    }
    recipeId && getRecipe();
  }, []);

  const recipeDetailsBackdrop = () => {
    setRecipeDetailsModal((current) => !current);
  };

  return (
    <>
      <Nav />
      <StyledExplore>
        <BackdropWrapper
          open={recipeDetailsModal}
          handleBackdrop={recipeDetailsBackdrop}
          element={
            <RecipeDetails
              data={queryRecipeData}
              handleBackdrop={recipeDetailsBackdrop}
            />
          }
        />
        {mediaQueryBtn ? (
          <RecipeButtons title="Explore" />
        ) : (
          <h3 className="heading">Explore</h3>
        )}
        <ExploreRecipes />
      </StyledExplore>
      <Footer />
    </>
  );
}

const StyledExplore = styled.section`
  margin: var(--section-margin) auto;
  width: var(--tablet-width);
  .heading {
    text-align: center;
    margin-bottom: var(--heading-margin-sm);
  }
`;

export default Explore;
