import { Route, Routes, useNavigate } from "react-router";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import MealPlanning from "./pages/MealPlanning";
import ProfileSetup from "./pages/ProfileSetup";
import ShoppingCart from "./pages/ShoppingCart";
import Signup from "./pages/Signup";
import Favorites from "./pages/Favorites";
import ErrorPage from "./pages/ErrorPage";
import Explore from "./pages/Explore";
import { useDispatch, useSelector } from "react-redux";
import PrivateRoute from "./authentication/PrivateRoute";
import UserProfile from "./pages/UserProfile";
import AlertBar from "./components/modals/AlertBar";
import DailyMeals from "./components/meal-planning/DailyMeals";
import { ThemeProvider } from "@emotion/react";
import muiTheme from "./styles/theme";
import ArchiveList from "./pages/ArchiveList";
import MealsNutrition from "./pages/MealsNutrition";
import AiRecipes from "./pages/AiRecipes";
// import InfoAlertBar from "./components/modals/InfoAlertBar";
import LandingPage from "./landing-page/LandingPage";
import ContactForm from "./components/ContactForm";
import BackdropWrapper from "./components/modals/BackdropWrapper";
import LoginModal from "./components/modals/LoginModal";
// import { modalActions } from "./store/modalSlice";
import Recipe from "./pages/Recipe";
import { useEffect, useState } from "react";
import { getRedirectResult } from "firebase/auth";
import { auth } from "./authentication/firebase";
import { userActions } from "./store/userSlice";
import AuthLoader from "./authentication/AuthLoader";
import TermsOfService from "./landing-page/TermsOfService";
import PrivacyPolicy from "./landing-page/PrivacyPolicy";
import DeleteUserData from "./pages/DeleteUserData";
import FilesToRecipes from "./pages/FilesToRecipes";
import { sendWeeklyEmail, sendWelcomeEmail } from "./utils/cloudFunctions";
import ShoppingCartRecipes from "./pages/ShoppingCartRecipes";
import CreatorLanding from "./landing-page/creator-landing/CreatorLanding";
import CreatorLanding2 from "./landing-page/creator-landing-2.0/CreatorLanding2";
import CreatorsProfile from "./pages/CreatorsProfile";
import CartPage from "./components/cart/CartPage.js";

function App() {
  const [redirectLoading, setRedirectLoading] = useState(true);
  const alert = useSelector((state) => state.alert);
  // const infoAlert = useSelector((state) => state.infoAlert);
  const loginModalActive = useSelector((state) => state.modal.loginModalActive);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const isNewUser = localStorage.getItem("isNewUser");
    if (isNewUser === null) {
      localStorage.setItem("isNewUser", true);
    }
    redirectURLHandler();
  }, []);

  async function redirectURLHandler() {
    try {
      const result = await getRedirectResult(auth);
      const user = result?.user;
      if (user) {
        dispatch(userActions.setCurrentUser(user));
        const from = {
          pathname:
            localStorage.getItem("routePath") !== "undefined"
              ? localStorage.getItem("routePath")
              : "/dashboard",
        };
        navigate(from);
        localStorage.removeItem("routePath");
        const isNewUser = localStorage.getItem("isNewUser") === "true";
        console.log("isNewUser", isNewUser);
        if (
          isNewUser &&
          user.metadata.creationTime === user.metadata.lastSignInTime
        ) {
          // google anaylytics signup trigger
          localStorage.setItem("isNewUser", false);
          await sendWelcomeEmail(user.email);
          await sendWeeklyEmail(user.email);
          window.dataLayer.push({
            event: "signup",
          });
        }
      }
    } catch (error) {
      console.error("error while redirecting google url", error);
    } finally {
      setRedirectLoading(false);
    }
  }

  if (redirectLoading) return <AuthLoader />;
  else
    return (
      <ThemeProvider theme={muiTheme}>
        <div style={{ position: "relative", minHeight: "90vh" }}>
          <AlertBar alertStates={alert} />
          {/* <InfoAlertBar alertStates={infoAlert} /> */}
          <BackdropWrapper
            open={loginModalActive}
            // smallSize={true}
            element={<LoginModal />}
          />
          <Routes>
            <Route path="/" element={<PrivateRoute />} />
            <Route path="/landing-page" element={<LandingPage />} />
            <Route path="/creators-landing" element={<CreatorLanding />} />
            <Route path="/creators-landing2" element={<CreatorLanding2 />} />
            <Route path="/creators-profile" element={<CreatorsProfile />} />

            {/* Temporary Cart Page */}
            <Route
              path="/cart/*"
              element={
                <PrivateRoute>
                  <CartPage />
                </PrivateRoute>
              }
            ></Route>

            {/* Temporary path="/landingpage" */}
            <Route path="/landingpage" element={<LandingPage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route
              path="/profile-setup/:id"
              element={
                <PrivateRoute>
                  <ProfileSetup />
                </PrivateRoute>
              }
            />
            <Route
              path="/profile"
              element={
                <PrivateRoute>
                  <UserProfile />
                </PrivateRoute>
              }
            />
            <Route path="/dashboard">
              <Route
                index
                element={
                  <PrivateRoute>
                    <Dashboard />
                  </PrivateRoute>
                }
              />
              <Route path="stock" element={<PrivateRoute></PrivateRoute>} />
              <Route
                path="meal-nutrition"
                element={
                  <PrivateRoute>
                    <MealsNutrition />
                  </PrivateRoute>
                }
              />
              <Route
                path="explore"
                element={
                  <PrivateRoute>
                    <Explore />
                  </PrivateRoute>
                }
              />
              <Route path="meal-planning">
                <Route
                  index
                  element={
                    <PrivateRoute>
                      <MealPlanning />
                    </PrivateRoute>
                  }
                />
                <Route
                  path=":date"
                  element={
                    <PrivateRoute>
                      <DailyMeals />
                    </PrivateRoute>
                  }
                />
              </Route>
              <Route
                path="ai-recipes"
                element={
                  <PrivateRoute>
                    <AiRecipes />
                  </PrivateRoute>
                }
              />
            </Route>
            <Route
              path="/meal-nutrition"
              element={
                <PrivateRoute>
                  <MealsNutrition />
                </PrivateRoute>
              }
            />
            <Route
              path="/explore"
              element={
                <PrivateRoute>
                  <Explore />
                </PrivateRoute>
              }
            />
            <Route path="/meal-planning">
              <Route
                index
                element={
                  <PrivateRoute>
                    <MealPlanning />
                  </PrivateRoute>
                }
              />
              <Route
                path=":date"
                element={
                  <PrivateRoute>
                    <DailyMeals />
                  </PrivateRoute>
                }
              />
            </Route>
            <Route
              path="/ai-recipes"
              element={
                <PrivateRoute>
                  <AiRecipes />
                </PrivateRoute>
              }
            />
            <Route path="/shopping-cart">
              <Route
                index
                element={
                  <PrivateRoute>
                    <ShoppingCart />
                  </PrivateRoute>
                }
              />
              <Route
                path="archive-list"
                element={
                  <PrivateRoute>
                    <ArchiveList />
                  </PrivateRoute>
                }
              />
            </Route>
            <Route
              path="/favorites"
              element={
                <PrivateRoute>
                  <Favorites />
                </PrivateRoute>
              }
            />
            <Route
              path="/cart-recipes"
              element={
                <PrivateRoute>
                  <ShoppingCartRecipes />
                </PrivateRoute>
              }
            />
            <Route
              path="/recipe/:recipeId"
              element={
                <PrivateRoute>
                  <Recipe />
                </PrivateRoute>
              }
            />
            <Route
              path="/files-to-recipes"
              element={
                <PrivateRoute>
                  <FilesToRecipes />
                </PrivateRoute>
              }
            />

            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route
              path="/delete-user-data"
              element={
                <PrivateRoute>
                  <DeleteUserData />
                </PrivateRoute>
              }
            />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
          {/* <ContactForm /> */}
        </div>
      </ThemeProvider>
    );
}
// }
// }

export default App;
